.bubble-list-item {
  padding-right: 0px !important;
}

.bubble-list-item .bubble.ai {
  margin-bottom: 30px;
}

.bubble {
  background-color: var(--bubble-bg-color);
  color: var(--bubble-text-color);
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
  margin: 5px 0;
  position: relative;
}

.bubble:last-of-type {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .bubble {
    max-width: 90%;
  }
}

.bubble.user {
  --bubble-bg-color: #d3e0ea;
  --bubble-text-color: black;
  margin-left: auto; /* Pushes the bubble to the right */
}

.bubble.ai {
  margin-left: 30px;
  --bubble-bg-color: #8574cb;
  --bubble-text-color: white;
}

.emi-icon {
  position: absolute;
  top: 0;
  left: -50px;
  height: 40px;
  width: 40px;
}

.ai-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.react-multi-carousel-item {
  max-width: 230px;
  min-width: 230px;
}

.tag-tile {
  padding: 10px;
  background-color: #fcf1fa;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  width: 200px; /* Ensure the tile takes full width of the carousel item */
}

.tag-image {
  width: 100px; /* Adjust width as needed */
  height: 100px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 8px;
}
