@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.gradient {
    --size: 500px;
    --speed: 10s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    width: var(--size);
    height: var(--size);
    filter: blur(calc(var(--size) / 5));
    background-image: linear-gradient(rgba(55, 235, 169, 0.85), #5b37eb);
    animation: rotate var(--speed) var(--easing) alternate infinite;
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
}

@media (min-width: 720px) {
    .gradient {
        --size: 700px;
    }
}

body {
    background-color: #222;
    position: absolute;
    inset: 0;
    display: flex;
    place-content: center;
    align-items: center;
    overflow: hidden;
}

/* This is just to transition when you change the viewport size. */
* {
    transition: all 0.25s ease-out;
}

body,
html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 1; /* Ensure content is above the gradient */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px; /* Space between the logo and the button */
}


.sign-in-button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #383D60;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sign-in-button:hover {
    background-color: #6d85b7;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.content p {
    animation: fadeIn 2s ease-in-out;
    font-size: 1.125rem; /* 18px if the root font-size is 16px */
}

.footer-logo {
    text-align: center;
    margin-top: 20px; /* Adjust the margin as needed */
}

.footer-logo img {
    transition: filter 0.3s ease; /* Smooth transition for the filter effect */
}

.footer-logo img:hover {
    filter: brightness(1.2); /* Increase brightness on hover */
}