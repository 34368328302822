.chat-input .MuiOutlinedInput-root {
    background-color: white;  
    border: 5px solid linear-gradient(135deg, #e9a1f2, #d291bc);
    border-radius: 30px;
    max-height: 150px;
    overflow: auto;
}

.chat-input .MuiInputBase-input {
    color: black; /* Change text color */
}