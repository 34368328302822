.emi-intro-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(135deg, #e9a1f2, #d291bc);
    border-radius: 16px;
    padding: 24px;
    width: 400px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
  }
  
  .emi-picture {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto 16px;
    margin-top: 30px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .emi-title {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
  }
  
  .emi-description {
    margin: 16px 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  .emi-subtitle {
    margin-top: 24px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .emi-list {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
    margin: 16px 0;
  }
  
  .emi-close-button {
    margin-top: 24px;
    background-color: #a050e0;
    color: white;
    &:hover {
      background-color: #8c40c8;
    }
  }


  @media (max-width: 600px) {
    .emi-intro-modal {
      width: 100vw;
      height: 100vh;
      margin: 0;
      border-radius: 0;
    }
  }