/* LoadingPlaceholder.css */

.loading-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .loading-placeholder {
    background: #ffffff;
    border-radius: 12px;
    padding: 32px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 400px; /* Adjust as needed */
  }
  
  .loading-text {
    color: #9589b0;
  }
  
  .loading-visual {
    width: 100%;
    height: 300px; /* Adjust the height for your 3D canvas container */
    /* important if you had a background color before: */
    background: transparent;
    position: relative; /* or 'absolute' if you prefer */
    top: -40px;
  }
  