.chat-page {
  display: flex;
  background-image: url("/public/EmiBackground.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.navbar-logo {
  height: 50px;
  /* Adjust the height as needed */
  width: auto;
}

.sign-out-button {
  white-space: nowrap;
  /* Prevent text from wrapping */
  width: auto;
  /* Adjust width as needed */
  padding: 8px 16px;
  /* Adjust padding as needed */
  font-size: 14px;
  /* Adjust font size as needed */
}

@media (max-width: 600px) {
  .sign-out-button {
    display: none !important; /* Hide on mobile */
  }
}