.message-actions {
    border-radius: 5px;
    background-color: #8574cb;
    position: absolute;
    top: 100%;
    left: 0%;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feedback-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #8a7da8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 80%;
    overflow-y: auto; /* Enables vertical scrolling */
  }

  .modal-close-button {
    position: absolute !important;
    top: 0px;
    right: 10px;
    color: #888;
  }
  
  .modal-title {
    margin-bottom: 16px;
  }
  
  .modal-textfield {
    margin-bottom: 16px;
  }
  
  .modal-description {
    margin-bottom: 16px;
  }
  
  .modal-tag-grid {
    margin-bottom: 16px;
  }
  
  .tag-chip {
    cursor: pointer;
  }
  
  .modal-submit-button {
    margin-top: 16px;
  }