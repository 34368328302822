.mobile-sign-out-button {
    margin-top: 20px !important;
    display: none !important; /* Hide by default */
}

@media (max-width: 600px) {
    .mobile-sign-out-button {
        display: block !important; /* Show on mobile */
    }
}

.get-started-button {
    margin-top: 20px !important;
}